<template>
  <b-row>
    <b-col sm="12" md="12">
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ $t("fields.noenter") }} </label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
              <b-button
                variant="primary"
                :to="{ name: GENAPP_APP_STORE_MODULE_NAME + '-add' }"
              >
                {{ $t("fields.addrecord") }}
              </b-button>
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />

              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refDataListTable"
          :items="fetchDatas"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #cell(holdername)="data">
            <span>
              {{ data.item.holdernamedata
              ? data.item.holdernamedata.data.name || ""
              : null }}
            </span>
          </template>
          <template #cell(cardnumber)="data">
            <span>
              {{ data.item.data.cardnumber }}
            </span>
          </template>

          <template #cell(actions)="data">
            <listaction
              :modulefield="GENAPP_APP_STORE_MODULE_NAME"
              :id="data.item.id"
              :options=[1,1,1,1]
              :checkdeletefun="checkdeletefun"
            ></listaction>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }}</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex mt-1 align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalDatas"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import listaction from "@/views/Component/listaction.vue";
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useList from "./useList";
import StoreModule from "./StoreModule";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    listaction,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    VBTooltip,
    BButton,
    BAvatar,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const GENAPP_APP_STORE_MODULE_NAME = "documents-rationcard";
    const modulename = "Ration Card";
    const modulenamesub = "rationcard";
    // Register module
    store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    const {
      fetchDatas,
      deletefun,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      statusFilter,
      refetchData,
      resolveDataRoleVariant,
    } = useList();
    return {
      modulename,
      modulenamesub,
      fetchDatas,
      deletefun,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      statusFilter,
      refetchData,
      avatarText,
      resolveDataRoleVariant,
      GENAPP_APP_STORE_MODULE_NAME,
    };
  },
  methods: {
    checkdeletefun(val) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletefun(val);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: this.modulename + " has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  wicabletv: 90px;
}
.electricity-filter-select {
  min-wicabletv: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    wicabletv: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
